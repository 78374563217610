import React from "react";
import Navbar from "./components/navbar/Navbar";
import Main from "./components/main/Main";
import Project from "./components/project/Project";
import Team from "./components/team/Team";
import About from "./components/about/About";
// import Hiring from "./components/hiring/Hiring";
import Contact from "./components/footer/Contact";
import Footer from "./components/footer/Footer";
import Heading from "./components/main/Heading";

const Main_file = () => {
  return (
    <>
      <div className="overflow-x-hidden">
        <Navbar />
        <Heading />
        <Main />
        <Project />
        <Team />
        <About />
        {/* <Hiring /> */}
        <Contact />
        <Footer />
      </div>
    </>
  );
};

export default Main_file;
