import React from "react";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Slider.css";

const Slider = ({ images }) => {
  // console.log(images);
  return (
    <>
      <div className="w-[21rem] md:w-[45rem] lg:w-[31rem]">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          grabCursor={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {images.map((image, index) => {
            // console.log("Item image:", image);
            return (
              <SwiperSlide key={index}>
                <img
                  alt={`Slide ${index}`}
                  // className="lg:w-1/2 w-full lg:h-auto h-60 object-center rounded"
                  src={image}
                  // src={"../../../public/apple_pay.png"}
                  // src="https://images.indianexpress.com/2014/03/phonesizes.jpg?w=414"
                  height={200}
                  width={200}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

export default Slider;
