import logo from "../../assets/logo.png";
import React, { useState } from "react";

export default function Navbar() {
  const NavbarList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Portfolio",
      link: "/projects_list",
    },

    // {
    //   name: "CONTACT US",
    //   link: "/",
    // },
  ];
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [selected, setSelected] = useState("Components");
  const toggleClass = () => {
    setIsNavOpen(!isNavOpen);
    const closeAfterClick = document.querySelector("#nav-icon4");
    closeAfterClick?.classList?.toggle("open");
  };
  return (
    <>
      <div className="lg:px-14 xl:px-28 bg-[#056eef] transition-all duration-700 relative w-full h-full right-0 left-0 z-50 top-0">
        <div className="flex justify-between w-full max-w-screen-3xl mx-auto font-semibold h-20 px-5">
          <div className="flex items-center gap-3 md:gap-4 mr-5">
            <a href="/">
              <img
                className="lg:h-16 h-12 lg:px-0 md:h-16 w-auto "
                src={logo}
                alt="logo"
              />
            </a>
          </div>
          <div className="flex items-center sm:gap-3 md:gap-8">
            {NavbarList.map((data, index) => (
              <div
                className="group"
                data-testid={`${data.name}-navlink`}
                key={index}
                onClick={() => setSelected(data.name)}
              >
                <a
                  href={data.link}
                  className="text-lg text-center font-semibold text-white opacity-80 cursor-pointer md:flex md:items-center hidden"
                >
                  {data.name}
                </a>
                {/* <span className="text-lg text-center font-semibold text-white opacity-80 cursor-pointer md:flex md:items-center hidden">
                  {data.name}
                </span> */}
                <div
                  className={`w-full h-0.5 ${
                    selected === data?.name
                      ? "bg-white opacity-80"
                      : "group-hover:bg-white group-hover:opacity-80"
                  }`}
                ></div>
              </div>
            ))}
            <button
              className="w-12 bg-transparent h-12 relative focus:outline-none md:hidden"
              onClick={() => {
                toggleClass();
                setToggle(!toggle);
              }}
            >
              <div className="block w-5 absolute left-6 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                <span
                  className={`block absolute h-0.5 w-7 text-white bg-current transform transition duration-300 ease-in-out ${
                    toggle ? "rotate-45" : "-translate-y-1.5"
                  }  
                `}
                ></span>
                <span
                  className={`block absolute h-0.5 w-7 text-white bg-current transform transition duration-300 ease-in-out ${
                    toggle && "opacity-0"
                  }`}
                ></span>
                <span
                  className={`block absolute h-0.5 w-7 text-white bg-current transform transition duration-300 ease-in-out ${
                    toggle ? "-rotate-45" : "translate-y-1.5"
                  }`}
                ></span>
              </div>
            </button>
          </div>
          <div
            className={`md:invisible w-full h-full flex flex-wrap flex-col justify-center fixed left-0 top-11 ${
              toggle ? "visible z-20" : "invisible -z-10"
            }`}
          >
            <div
              className={`w-full h-full bg-[#056eef] absolute left-0 transition-all duration-300 ease-in-out top-8 ${
                toggle ? "sm:w-3/5 " : "ssm:w-0 -z-10"
              }`}
            ></div>
            <div
              data-tilt
              data-tilt-perspective="2000"
              className="relative z-20 text-center w-full sm:w-3/5"
            >
              <div
                className={`flex min-h-[130px] w-fit mx-auto transform transition ${
                  toggle
                    ? "opacity-100 -translate-y-1/3 delay-[0.45s]"
                    : "opacity-0 -translate-y-0  delay-[0s]"
                }`}
              >
                <ul
                  // className={`transition w-fit flex flex-col gap-5 justify-center items-center my-[13rem] mx-[-6.4rem] ${
                  className={`transition w-fit flex flex-col gap-5 justify-center items-center ${
                    toggle ? "delay-[0.45s]" : "delay-[0s]"
                  }`}
                >
                  {NavbarList.map((data, index) => (
                    <a
                      href={data.link}
                      className="font-semibold text-white text-2xl text-opacity-100 text-center cursor-pointer px-2 md:hidden hover:border-b-2 border-white"
                      key={index}
                    >
                      {data.name}
                    </a>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
