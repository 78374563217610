import React from "react";

const truncateText = (text, wordLimit) => {
  const words = text.split(" ");
  if (words.length <= wordLimit) {
    return text;
  }
  return words.slice(0, wordLimit).join(" ") + " ...";
};

const Project_description = ({ project }) => {
  // console.log(project);
  const truncatedDescription = truncateText(project, 81);

  return (
    <>
      <div className="leading-relaxed my-6 lg:mr-12 text-justify lg:text-xl">
        {truncatedDescription}
      </div>
    </>
  );
};

export default Project_description;
