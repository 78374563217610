import React from "react";
import "./Main.css";
import rocket from "../../assets/svgs/rocket.svg";
import energy from "../../assets/svgs/energy.svg";
import heart from "../../assets/svgs/heart.svg";
// import Font from "../Font";
// import { TypeAnimation } from "react-type-animation";
// import Particle from "../particle/Particle";

const Main = () => {
  return (
    <>
      <section>
        {/* <div className="container px-5 ml-3 lg:ml-44 mx-auto"> */}
        {/* <div className="container px-9 sm:ml-2  md:ml-0 xl:ml-7 lg:ml-44 xl:h-[35rem] xl:py-16 mx-auto z-0"> */}
        <div className="container px-9 xl:py-16 mx-auto z-0">
          {/* <div className="container px-5 sm:ml-2  md:ml-0 xl:ml-[60rem] mx-auto z-0"> */}
          <div className="flex flex-wrap -m-4 gap-y-8 justify-center">
            <div className="lg:w-[30%] block bg-white border lg:pt-3 hover:drop-shadow-xl border-gray-200 rounded-lg shadow hover:bg-sky-100 transition duration-300 ease-in-out hover:scale-110">
              <div className="h-full px-10 lg:px-16 pb-23 rounded-lg overflow-hidden text-center relative">
                <img
                  src={rocket}
                  alt="rocket"
                  className="m-auto w-[24%] mt-[8%] lg:w-[100px] lg:mt-0"
                  // width={100}
                />
                {/* <h2 className="title-font sub_title text-2xl lg:text-3xl font-medium z-[99] mb-3 xl:mb-14"> */}
                <h2 className="title-font sub_title text-2xl lg:text-3xl font-medium z-[99] mb-3 xl:mb-6">
                  From Zero to One
                </h2>
                <p className="leading-relaxed text-justify	custom__para text-base lg:text-lg text-balance mb-3">
                  {/* We are an Information and Communication Technology (ICT)
                  company specialized in the design and development of
                  management systems. We combine vertical market expertise with
                  the advantages from innovative technologies, such as Big Data,
                  Social Networking, Cloud Computing, to optimize and integrate
                  processes, applications and devices for customer service. */}
                  We are an Information and Communication Technology (ICT)
                  company specialized in the design and development of
                  management systems. We combine vertical market expertise with
                  the advantages from innovative technologies.
                </p>
              </div>
            </div>
            {/* <div className="lg:w-[30%] lg:pt-3 hover:border-black hover:border-[2px] bg-white hover:rounded-lg hover:bg-gradient-to-r hover:from-blue-200 hover:to-cyan-200"> */}
            {/* <div className="lg:w-[33%] block bg-white border lg:mx-12 lg:pt-3 hover:drop-shadow-xl border-gray-200 rounded-lg shadow hover:bg-sky-100 transition duration-300 ease-in-out hover:scale-110"> */}
            <div className="lg:w-[28%] block bg-white border lg:mx-12 lg:pt-3 hover:drop-shadow-xl border-gray-200 rounded-lg shadow hover:bg-sky-100 transition duration-300 ease-in-out hover:scale-110">
              <div className="h-full px-12 pb-23 rounded-lg overflow-hidden text-center relative">
                <img
                  src={energy}
                  alt="energy"
                  className="m-auto w-[24%] mt-[8%] lg:w-[100px] lg:mt-0"
                />
                <h2 className="title-font sub_title text-2xl lg:text-3xl font-medium z-[99] mb-3 xl:mb-6">
                  Our Level Up
                </h2>
                <p className="leading-relaxed text-justify text-balance custom__para text-base lg:text-lg mb-3">
                  The presence of professionalism coming from the consulting
                  sector and from the most established system integrators, with
                  experience of know-how and complementary backgrounds, has
                  allowed us to create a close, dynamic and reactive team. We
                  believe that innovation is a key for increasing knowledge and
                  developing new business opportunities.
                </p>
              </div>
            </div>
            <div className="lg:w-[30%] block bg-white border  lg:pt-3 hover:drop-shadow-xl border-gray-200 rounded-lg shadow hover:bg-sky-100 transition duration-300 ease-in-out hover:scale-110">
              <div className="h-full px-12 pb-23 rounded-lg overflow-hidden text-center relative">
                <img
                  src={heart}
                  alt=""
                  className="m-auto w-[20%] mt-[8%] lg:w-[100px] lg:mt-0"
                />
                <h2 className="title-font sm:text-2xl sub_title text-2xl lg:text-3xl font-medium mb-3 xl:mb-14">
                  Your Satisfaction
                </h2>
                <p className="leading-relaxed text-justify	text-balance custom__para text-base lg:text-lg mb-3">
                  We experiment with new technologies in our Industry, creating
                  intuitive and easy-to-use tools, improving user’s experience.
                  We support companies in this new way of interpreting the
                  network, helping them to find new business models and
                  providing them with a domain of new solutions available today.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
