import React, { useEffect } from "react";
import "./Team.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Team = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      {/* <ThreeD_font /> */}
      <section className="body-font mt-4">
        <div
          className="flex flex-col h-full w-full mb-10"
          data-aos="zoom-in-left"
          data-aos-duration="3000"
        >
          <div className="font-medium text-2xl md:text-3xl sm:text-4xl text-center align-middle">
            <span className="project">TOTAL</span> PROJECTS{" "}
            <span className="project text-5xl sm:text-7xl md:text-6xl">32</span>
          </div>
        </div>

        <div
          className="flex flex-col text-center w-full mb-8"
          data-aos="zoom-in-right"
          data-aos-duration="2000"
        >
          <div className="font-medium text-2xl md:text-3xl sm:text-4xl text-center ">
            <span className="project">SATISFIED</span> CUSTOMERS{" "}
            <span className="project text-5xl sm:text-7xl mt-4 md:text-6xl">
              27
            </span>
          </div>
        </div>

        <div className="container px-5 md:py-5 py-24 sm:py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            {/* <div className="relative mb-4"> */}
            <div className="text">
              <h1
                data-aos="zoom-in"
                data-aos-duration="2000"
                className="text-4xl sm:text-7xl leading-normal md:text-6xl project_title font-semibold title-font mb-8 tracking-widest"
              >
                OUR <span className="project"> TEAM</span>
              </h1>
            </div>
            {/* <div className="relative top-[15rem]">
              <ThreeD_font />
            </div> */}
            {/* </div> */}

            <p
              className="text-xl md:text-2xl sm:text-3xl font-medium"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              “We work on innovative projects and develop ideas.”
            </p>
            <p
              className="text-xl sm:text-3xl md:text-2xl font-medium mb-8"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              “Knowing the topic is just the starting point.”
            </p>

            <h2
              data-aos="zoom-out"
              data-aos-duration="2000"
              className="font-bold project md:text-xl text-2xl sm:text-[2rem] mb-5"
            >
              YOUNG AND DYNAMIC TEAM
            </h2>

            <p
              data-aos="zoom-out"
              data-aos-duration="2000"
              className="font-medium text-2xl md:text-xl sm:text-[2rem] md:px-32 text-black sm:px-80 text-center "
            >
              We are a close young group, consisting in professionals of
              different technologies and consulting areas, constructing a
              dynamic and multidisciplinary experienced team with know-how and
              complementary background.
            </p>

            <h3
              data-aos="zoom-out"
              data-aos-duration="2000"
              className="text-3xl md:text-2xl sm:text-[2.5rem] mt-8 md:px-32 sm:px-80 text-grayColor"
            >
              Let’s take a closer look on how our productive team is created
            </h3>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
