import React from "react";
import "./Project_list.css";
import Slider from "../Slider/Slider";
import { useNavigate } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import Data from "./Data.json";
import Project_description from "./Project_description";

const Project_list = () => {
  const navigation = useNavigate();
  const handleClick = (projectId) => {
    navigation(`/projects${projectId}`);
  };

  console.log(Data.projects1);

  const projects = [
    Data.projects1,
    Data.projects2,
    Data.projects3,
    Data.projects4,
  ];

  return (
    <>
      <Navbar />
      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-4 lg:py-24 mx-auto">
          <div className="flex text flex-col text-center w-full mb-4 lg:mb-20">
            <h1 className="text-4xl leading-tight md:text-6xl project_title font-semibold title-font mb-4 tracking-widest">
              OUR <span className="project uppercase"> portfolio </span>
            </h1>
          </div>
          {projects.map((project, index) => (
            <div
              className={`lg:w-4/5 mx-auto flex flex-wrap my-6 lg:my-24 ${
                index % 2 === 0
                  ? "flex-col lg:flex-row"
                  : "flex-col lg:flex-row-reverse"
              }`}
              key={index}
            >
              <div className="lg:w-1/2 w-full">
                <Slider images={project.main_slide} />
              </div>
              <div className="lg:w-1/2 w-full lg:pl-10 mt-6 lg:mt-0 flex flex-col justify-center">
                <h1 className="text-gray-900 text-xl lg:text-3xl title-font font-medium mb-1">
                  {project.title}
                </h1>
                <Project_description project={project.des} />
                <button
                  className="rounded-md hover:before:bg-redborder-red-500 relative h-[30px] w-[8rem] lg:h-[50px] lg:w-40 overflow-hidden border border-blue-500 bg-white px-3 text-blue-500 shadow-2xl transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-blue-500 before:transition-all before:duration-500 hover:text-white hover:shadow-blue-500 hover:before:left-0 hover:before:w-full"
                  onClick={() => handleClick(index + 1)}
                >
                  <span className="relative lg:text-lg z-10">Explore More</span>
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Project_list;
