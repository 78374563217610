import React from "react";
import img1 from "../../assets/about 1.png";
import img2 from "../../assets/about 2.png";
// import img3 from "../../assets/img3.png";
import "./About.css";

const About = () => {
  return (
    <>
      <section className="body-font">
        {/* <div className="container sm:pl-6 mx-auto"> */}
        <div className="container mx-auto">
          <div className="flex items-center md:px-20 xl:px-20 lg:px-0 xl:w-3/5 lg:w-5/6 mx-auto pb-10 mb-10 sm:flex-row flex-col">
            <div
              className="sm:w-64 sm:h-64 h-64 w-64 sm:mr-10 inline-flex items-center justify-center rounded-full  flex-shrink-0"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              <img src={img1} alt="designer" />
            </div>
            <div
              className="flex-grow sm:text-center text-center mt-6 sm:mt-0"
              data-aos="zoom-in-left"
              data-aos-duration="2000"
            >
              <h2 className="text-3xl text md:text-4xl sm:text-5xl title-font custom__font_1 mb-2">
                DESIGNER
              </h2>
              <p className="text-lg md:text-xl px-8 sm:px-0 sm:text-2xl leading-relaxed text-justify">
                The Designer is the translator of aesthetics and functional
                needs of a specific sector such as road systems, IT or
                management, in simple, intuitive and user interface (UI, UX,
                GRAPHIC DESIGN). This process uses processes and innovative
                projecting technique (design sprint).
              </p>
            </div>
          </div>
          <div className="flex items-center md:px-20 xl:px-20 lg:px-0 xl:w-3/5 lg:w-5/6 mx-auto  pb-10 mb-10  sm:flex-row flex-col">
            <div
              className="flex-grow sm:text-center text-center mt-6 sm:mt-0"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              <h2 className="text-3xl text md:text-4xl sm:text-5xl title-font custom__font_1 mb-2">
                DEVELOPER
              </h2>
              <p className="text-lg md:text-xl sm:text-2xl px-8 sm:px-0 text-justify leading-relaxed">
                The Developer often faces problems or projects that require
                specific solutions. By using technologies as JAVA, KOTLIN,
                OBJECT C, SWIFT, REACT, REACT NATIVE, JAVASCRIPT, EXPRESS JS,
                NODE JS, NEXT JS, etc, the result is a complex project within
                the team offering new experiences and increasing Industry’s
                opportunities for growth.
                {/* The Developer often faces problems or projects that require
                specific solutions. By using technologies as JAVA, SCALA,
                FUNCTIONAL PROGRAMMING , etc, the result is a complex project
                within the team offering new experiences and increasing
                Industry’s opportunities for growth. */}
              </p>
            </div>
            <div
              className="sm:w-64 sm:order-none order-first sm:h-64 h-64 w-64 sm:ml-10 inline-flex items-center justify-center rounded-full  flex-shrink-0"
              data-aos="zoom-in-left"
              data-aos-duration="2000"
            >
              <img src={img2} alt="developer" className="img-fluid" />
            </div>
          </div>
          {/* <div className="flex items-center md:px-20 xl:px-20 lg:px-0  lg:w-3/5 mx-auto sm:flex-row flex-col"> */}
          {/* <div className="flex items-center md:px-20 xl:px-20 lg:px-0 xl:w-3/5 lg:w-5/6 mx-auto sm:flex-row flex-col">
            <div className="sm:w-64 sm:h-64 h-64 w-64 sm:mr-10 inline-flex items-center justify-center rounded-full  flex-shrink-0">
              <img src={img3} alt="analyst" className="img-fluid" />
            </div>
            <div className="flex-grow sm:text-center text-center mt-6 sm:mt-0">
              <h2 className="text-3xl md:text-4xl sm:text-5xl title-font custom__font_1 mb-2">
                ANALYST
              </h2>
              <p className="text-lg md:text-xl sm:text-2xl px-8 sm:px-0 leading-relaxed">
                The Functional Analyst is responsible for the success,
                opportunity, development and creation and the external
                collaboration of a project. While working in the management of
                complex information systems, RAYONIT looks beyond, inspiring new
                ideas and reaching new markets.
              </p>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default About;
