import React, { useState } from "react";
import prev from "../../assets/prev.gif";
import next from "../../assets/next.gif";
import "./Model.css";
import "swiper/css";

const Modal = ({ isOpen, onClose, image, onNext, onPrev }) => {
  // Initialize all useState hooks unconditionally at the top level
  const [animateOut, setAnimateOut] = useState(false);
  const [slideLeft, setSlideLeft] = useState(false);
  const [slideRight, setSlideRight] = useState(false);

  // Early return if modal is not open or image is not provided
  if (!isOpen || !image) return null;

  const handleBackgroundClick = (event) => {
    if (event.target === event.currentTarget) {
      // setAnimateOut(true);
      // setTimeout(onClose, 800);

      setAnimateOut(true);
      setTimeout(() => {
        setAnimateOut(false);
        onClose();
      }, 800);
    }
  };

  const handlePrevClick = () => {
    setSlideLeft(true);
    setTimeout(() => {
      onPrev();
      setSlideLeft(false);
    }, 500);
  };

  const handleNextClick = () => {
    setSlideRight(true);
    setTimeout(() => {
      onNext();
      setSlideRight(false);
    }, 500);
  };

  return (
    <div
      onClick={handleBackgroundClick}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    >
      <button
        onClick={handlePrevClick}
        className="absolute left-4 md:top-[31rem] lg:top-[26rem] md:left-32 lg:left-60 xl:left-[28rem] 2xl- bg-slate-200 rounded-full text-sm w-9 h-9 inline-flex justify-center items-center animate-slideInRight transition duration-300"
      >
        <img src={prev} alt="prev" className="w-5 h-5" />
        <span className="sr-only">Previous</span>
      </button>
      <button
        onClick={handleNextClick}
        className="absolute right-4 md:top-[31rem] lg:top-[26rem] md:right-32 lg:right-60 xl:right-[28rem] bg-slate-200 rounded-full text-sm w-9 h-9 inline-flex justify-center items-center transition duration-300"
      >
        <img src={next} alt="next" className="w-5 h-5" />
        <span className="sr-only">Next</span>
      </button>{" "}
      <div
        className={`relative bg-white dragClass rounded-lg shadow dark:bg-gray-700 animate-slideInRight p-4 md:p-5 ${
          animateOut
            ? "zoom-out"
            : slideLeft
            ? "slide-left"
            : slideRight
            ? "slide-right"
            : "zoom-in"
        }`}
      >
        <img
          src={image}
          alt="Enlarged"
          className={`rounded h-[30rem] lg:h-[52rem] transition-all`}
        />
      </div>
    </div>
  );
};

export default Modal;
