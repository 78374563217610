import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { MailtrapClient } from "mailtrap";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    const name = formData.get("name");
    const email = formData.get("email");
    const message = formData.get("message");

    if (!name || !email || !message) {
      toast.error("All fields are required!", {
        position: "top-right",
      });
      return;
    }

    emailjs
      .sendForm("service_h4i2iz8", "template_woj9dwe", form.current, {
        publicKey: "fL78Q3B3xTrpnWY-6",
      })
      .then(
        () => {
          toast.success(
            "Your request is submitted successfully. We will contact you ASAP!",
            {
              position: "top-right",
            }
          );
        },
        (error) => {
          toast.error(`Try again`, {
            position: "top-right",
          });
        }
      );

    form.current.reset();
  };

  return (
    <>
      <section className="ml-4 body-font relative">
        <ToastContainer />
        {/* <div className="container pr-4 py-24 mx-auto sm:pb-24 sm:py-0"> */}
        <div className="container pr-4 lg:py-24 mx-auto sm:pb-24 sm:py-0">
          <div className="flex flex-col text text-center w-full mb-12">
            <h1 className="text-4xl sm:text-7xl leading-normal md:text-6xl project_title font-semibold title-font mb-4 tracking-widest">
              CONTACT <span className="project"> US</span>
            </h1>
          </div>
          <div className="xl:w-1/2 md:w-2/3 mx-auto">
            <form
              className="flex flex-wrap -m-2"
              onSubmit={sendEmail}
              ref={form}
            >
              <div className="p-2 w-full xl:w-1/2 sm:w-1/2 lg:w-full">
                <div className="relative">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="name"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-lg outline-none text-gray-700 xl:py-1 py-1 lg:py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-full xl:w-1/2 sm:w-1/2 lg:w-full">
                <div className="relative">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="email"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-lg outline-none text-gray-700 xl:py-1 py-1 lg:py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Message"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-lg outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  ></textarea>
                </div>
              </div>
              <div className="p-2 w-full">
                {/* <button className="flex mx-auto text-white py-2 px-8 focus:outline-none md:text-xl text-2xl">
                  Submit
                </button> */}

                {/* <button
                  type="submit"
                  className="text-white flex mx-auto bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-10 py-2.5 text-center  mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Submit
                </button> */}

                <div className="flex items-center justify-center">
                  <button className="rounded-md hover:before:bg-redborder-red-500 relative h-[30px] w-[8rem] lg:h-[50px] lg:w-40 overflow-hidden border border-blue-500 bg-white px-3 text-blue-500 shadow-2xl transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-blue-500 before:transition-all before:duration-500 hover:text-white hover:shadow-blue-500 hover:before:left-0 hover:before:w-full">
                    <span className="relative lg:text-lg z-10">Submit</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
