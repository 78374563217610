import React, { useEffect, useState } from "react";
import Slider from "../Slider/Slider";
import Tech_Slider from "../Slider/Tech_Slider";
import Tab from "./Tab";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { useParams } from "react-router-dom";
import Data from "./Data.json";

const Project_details = () => {
  const { projectId } = useParams();
  const [projectData, setProjectData] = useState(null);

  useEffect(() => {
    const data = Data[projectId];
    setProjectData(data);
  }, [projectId]);

  if (!projectData) {
    return <div>Loading...</div>;
  }

  const { title, des, main_slide, technology, third_party, portfolio } =
    projectData;

  console.log(main_slide);
  return (
    <>
      <Navbar />
      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 xl:px-12 py-4 lg:py-24 mx-auto">
          <div className="flex text flex-col text-center w-full mb-4 lg:mb-20">
            <h1 className="text-4xl leading-tight md:text-6xl project_title font-semibold title-font mb-4 tracking-widest">
              OUR <span className="project uppercase"> portfolio </span>
            </h1>
          </div>
          <h1 className="text-gray-900 text-3xl pb-4 title-font font-medium mb-4">
            {title}
          </h1>
          <div className="lg:w-full mx-auto flex flex-wrap">
            <div className="lg:w-1/2 w-full">
              <Slider images={main_slide} />{" "}
            </div>
            {/* <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0 flex flex-col justify-center"> */}
            <div className="lg:w-1/2 w-full mt-6 lg:mt-0 flex flex-col justify-center">
              {/* <h1 className="text-gray-900 text-3xl title-font font-medium mb-1">
                {title}
              </h1> */}
              <p className="leading-relaxed mb-6 md:text-base text-justify lg:text-lg">
                {des}
              </p>
              <h1 className="text-gray-900 text-2xl lg:text-3xl title-font font-medium mb-1 border-b-2 border-blue-500 mr-[11.5rem] md:mr-[36.5rem] lg:mr-[36rem] xl:mr-[26rem] 2xl:mr-[34rem]">
                Technologies
              </h1>
              <div className="my-4 cursor-grab">
                <Tech_Slider images={technology} />
              </div>
              <h1 className="text-gray-900 text-2xl lg:text-3xl title-font font-medium my-8 pb-2 border-b-2 border-blue-500 lg:mr-[27rem] md:mr-[29.5rem] xl:mr-[17rem] mr-[4.5rem] 2xl:mr-[25rem]">
                Third Party Integrations
              </h1>
              <div className="cursor-grab">
                <Tech_Slider images={third_party} />
              </div>
            </div>
          </div>
        </div>

        <Tab portfolio={portfolio} />

        {/* <div className="font-sans flex flex-col h-72 lg:h-96 items-center justify-center">
          <h1 className="text-gray-900 text-3xl title-font font-medium mb-1 pb-2 border-b-2 border-blue-500 ">
            Project Overview
          </h1>
          <p className="mb-3 ml-4 text-xl lg:px-40 custom_title leading-relaxed">
            “The innovation consists in seeing what everyone has seen and in
            thinking what no one has thought.”
          </p> */}
        {/* <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae
            voluptatum, possimus illo quae laudantium harum id, quisquam dolore
            provident eum fugit doloremque blanditiis eius minima suscipit ullam
            autem explicabo maxime?
          </p> */}
        {/* </div> */}
      </section>
      <Footer />
    </>
  );
};

export default Project_details;
