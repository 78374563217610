import React from "react";
import { TypeAnimation } from "react-type-animation";
import Particle from "../particle/Particle";
import "./Heading.css";

const Heading = () => {
  return (
    <>
      <div className="relative ">
        <Particle />
        {/* <div className="container mx-auto h-[56rem] sm:[38rem] flex sm:px-5 sm:py-24 items-center justify-center flex-col"> */}
        {/* <div className="container mx-auto h-[45rem] md:h-[60rem] lg:h-[53rem] md:ml-[-3rem] lg:ml-44 sm:ml-0 flex sm:px-5 sm:py-24 lg:py-0 items-center justify-center flex-col"> */}
        <div className="container text-center h-[40rem] md:h-[60rem] lg:h-[53rem] flex items-center justify-center max-w-[120rem] w-screen">
          {/* <div className="text-center lg:w-2/3 w-full md:w-[75%]"> */}
          <div className="text-center lg:w-2/3 w-full">
            {/* <h1 className="title-font small md:text-4xl text-4xl lg:text-7xl mb-4 font-extrabold "> */}
            <h1 className="title-font text-1 md:text-4xl text-4xl lg:text-7xl mb-4 font-extrabold ">
              {/* <h1 className="title-font small-2 md:text-4xl text-4xl lg:text-7xl mb-4 font-extrabold "> */}
              {/* <h1 className="title-font small-3 md:text-4xl text-4xl lg:text-7xl mb-4 font-extrabold "> */}
              {/* Helping Business With Tech */}
              {/* <Font text={"Helping Business With Tech"} /> */}
              <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed out once, initially
                  "Helping business with design",
                  1000,
                  "Helping business with development",
                  1000,
                  // "Helping business with tech",
                  // 1000,
                ]}
                wrapper="span"
                speed={30}
                // style={{ fontSize: "2em", display: "inline-block" }}
                repeat={Infinity}
              />
            </h1>
            {/* <p className="mb-3 ml-4 small-1 text-base md:text-xl lg:text-2xl lg:px-50 custom_title leading-relaxed"> */}
            {/* <p className="mb-3 ml-4 small-4 text-base md:text-xl lg:text-2xl lg:px-50 leading-relaxed"> */}
            <p className="mb-3 ml-4 text-2 text-base md:text-xl lg:text-2xl lg:px-50 leading-relaxed">
              “The innovation consists in seeing what everyone has seen and in
              thinking what no one has thought.”
            </p>

            {/* <div className="flex justify-center">
              <button className="inline-flex text-black hover:text-white border-0 py-2 px-6 focus:outline-none  rounded text-lg">
                Become one of us
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Heading;
