import React from "react";
import { Route, Routes } from "react-router-dom";
import Main_file from "./Main_file";
import Project_list from "./components/project/Project_list";
import Project_details from "./components/project/Project_details";
import "./App.css";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Main_file />} />
      <Route path="/projects_list" element={<Project_list />} />
      <Route path="/:projectId" element={<Project_details />} />
    </Routes>
  );
}

export default App;
