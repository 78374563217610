import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper/modules";
import Modal from "./Model";

const Img = ({ images }) => {
  const [clickedIndex, setClickedIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const swiperRef = useRef(null);

  const duplicatedImages = [...images, ...images];

  const handleClick = (index) => {
    // setClickedIndex(index === clickedIndex ? null : index);
    setClickedIndex(index);
    setModalImage(duplicatedImages[index]);
    setModalOpen(true);
  };

  const handleNext = () => {
    const nextIndex = (clickedIndex + 1) % duplicatedImages.length;
    setClickedIndex(nextIndex);
    setModalImage(duplicatedImages[nextIndex]);
  };

  const handlePrev = () => {
    const prevIndex =
      (clickedIndex - 1 + duplicatedImages.length) % duplicatedImages.length;
    setClickedIndex(prevIndex);
    setModalImage(duplicatedImages[prevIndex]);
  };

  const handleClose = () => {
    setModalOpen(false);
    setTimeout(() => {
      setClickedIndex(null);
      setModalImage(null);
    }, 800);
  };

  useEffect(() => {
    const swiper = swiperRef.current?.swiper;
    const handleSlideChange = () => {
      setClickedIndex(null);
      // setModalOpen(false);
    };

    swiper?.on("slideChange", handleSlideChange);

    return () => {
      swiper?.off("slideChange", handleSlideChange);
    };
  }, []);

  return (
    <>
      <Swiper
        ref={swiperRef}
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        speed={3000}
        breakpoints={{
          360: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
        {duplicatedImages.map((item, index) => (
          <SwiperSlide key={index} className="mb-1">
            <div
              className={`h-[23rem] w-fit ${
                clickedIndex === index
                  ? "scale-105 transition duration-700 ease-in-out"
                  : ""
              }`}
              onClick={() => handleClick(index)}
            >
              <img
                alt="html"
                className={`object-cover object-center rounded ${
                  clickedIndex === index
                    ? "drop-shadow-xl border-gray-200 rounded-lg shadow bg-sky-100 overflow-visible"
                    : ""
                }`}
                src={item}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Modal
        isOpen={modalOpen}
        onClose={handleClose}
        image={modalImage}
        onNext={handleNext}
        onPrev={handlePrev}
      />
    </>
  );
};

export default Img;
