import React, { useState } from "react";
import Img from "../Slider/Img";

const Tab = ({ portfolio }) => {
  const [openTab, setOpenTab] = useState(1);

  return (
    // <div className="bg-gray-100 font-sans flex h-screen items-center justify-center">
    <div className="bg-gray-100 font-sans flex items-center justify-center">
      {/* <div className="p-8"> */}
      <div className="p-4">
        {/* <div className="lg:w-[50rem] mx-auto"> */}
        <div className="w-[21rem] md:w-[40rem] lg:w-[50rem] lg:h-[32rem] mx-auto">
          <div className="mb-4 flex space-x-4 p-2 bg-white rounded-lg shadow-md">
            <button
              onClick={() => setOpenTab(1)}
              className={`flex-1 py-2 lg:text-lg px-4 rounded-md focus:outline-none focus:shadow-outline-blue transition-all duration-300 ${
                openTab === 1 ? "bg-blue-600 text-white" : ""
              }`}
            >
              Mobile Application
            </button>
            {/* <button
              onClick={() => setOpenTab(2)}
              className={`flex-1 py-2 px-4 rounded-md focus:outline-none focus:shadow-outline-blue transition-all duration-300 ${
                openTab === 2 ? "bg-blue-600 text-white" : ""
              }`}
            >
              ANDROID
            </button>
            <button
              onClick={() => setOpenTab(3)}
              className={`flex-1 py-2 px-4 rounded-md focus:outline-none focus:shadow-outline-blue transition-all duration-300 ${
                openTab === 3 ? "bg-blue-600 text-white" : ""
              }`}
            >
              WEB
            </button> */}
          </div>

          <div
            // className="transition-all duration-300 bg-white lg:h-[38rem] p-4 rounded-lg shadow-md border-l-4 border-blue-600"
            className="transition-all duration-300 bg-white over lg:h-[27rem] p-4 rounded-lg shadow-md border-l-4 border-blue-600"
            style={{ display: openTab === 1 ? "block" : "none" }}
          >
            <Img images={portfolio} />
          </div>

          <div
            className="transition-all duration-300 bg-white p-4 rounded-lg shadow-md border-l-4 border-blue-600"
            style={{ display: openTab === 2 ? "block" : "none" }}
          >
            <img
              src="https://images.indianexpress.com/2014/03/phonesizes.jpg?w=414"
              alt="android device"
            />
          </div>

          <div
            className="transition-all duration-300 bg-white p-4 rounded-lg shadow-md border-l-4 border-blue-600"
            style={{ display: openTab === 3 ? "block" : "none" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Tab;
