// import React from "react";
// import "./Footer.css";
// import linkedIn from "../../assets/svgs/linkedIn.svg";
// import fb from "../../assets/svgs/fb.svg";
// import twitter from "../../assets/svgs/twter.svg";

// const Footer = () => {
//   return (
//     <>
//       {/* <section className="footer__container ml-4 body-font relative"> */}
//       <section className="ml-4 body-font relative">
//         {/* <div className="container px-5 py-24 mx-auto"> */}

//         <div className="flex flex-col lg:flex-row">
//           <div className="p-2 w-full sm:w-1/2 pt-8 px-10 sm:px-20 text-start">
//             <h4 className="sm:text-base blue__font">ADDRESS</h4>
//             <p className="leading-normal my-3 pr-12 lg:pr-4 xl:pr-72 md:pr-8 sm:pr-[30rem]">
//               A, 616, Titanium Business Park, Nr. Makarba Railway Crossing, B/H.
//               Divya Bhaskar Press, Ahmedabad - 380007
//             </p>

//             <div className="sm:flex mb-10 sm:mb-5">
//               <div className="py-8 ">
//                 <h4 className="sm:text-base blue__font">PHONE</h4>
//                 <p className="text-nowrap">+91 7359910659</p>
//               </div>
//               <div className="sm:px-7 sm:py-8">
//                 <h4 className="sm:text-base blue__font">EMAIL</h4>
//                 <p>waytoreacttechnologies@gmail.com</p>
//               </div>
//             </div>
//             <span className="inline-flex">
//               <a className="text-gray-500">
//                 <img src={linkedIn} alt="" />
//               </a>
//               <a className="ml-4 text-gray-500">
//                 <img src={fb} alt="" />
//               </a>
//               <a className="ml-4 text-gray-500">
//                 <img src={twitter} alt="" />
//               </a>
//             </span>
//           </div>
//           <div className="w-full sm:w-1/2 flex justify-start md:justify-start lg:justify-end sm:justify-end px-10 sm:pl-44 md:pl-0 md:ml-20 my-10 lg:mt-20 xl:mt-44 md:mt-16">
//             <span className="inline-flex sm:flex-row flex-col justify-start ">
//               <a className="text-gray-500 uppercase" href="/projects_list">
//                 portfolio
//               </a>
//               <a className="sm:ml-10 my-4 sm:my-0 text-gray-500">OUR TEAM</a>
//               <a className="sm:ml-10 text-gray-500">CONTACT US</a>
//             </span>
//           </div>
//         </div>

//         <div className="text-center my-3">
//           <p>All Rights Reserved © 2024 WAYTOREACT TECHNOLOGIES</p>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Footer;

import React from "react";
import "./Footer.css";
import linkedIn from "../../assets/svgs/linkedIn.svg";
import fb from "../../assets/svgs/fb.svg";
import twitter from "../../assets/svgs/twter.svg";

const Footer = () => {
  return (
    <>
      {/* <section className="footer__container ml-4 body-font relative"> */}
      <section className="body-font relative">
        {/* <div className="container px-5 py-24 mx-auto"> */}

        <div className="flex flex-col lg:flex-row">
          <div className="p-2 w-full flex xl:items-center flex-col xl:flex-row justify-between pt-8 px-10 sm:px-20 text-start">
            <div className="xl:w-[23rem]">
              <h4 className="sm:text-base blue__font">ADDRESS</h4>
              <p className="leading-normal my-3 pr-12 lg:pr-4 xl:pr-0 md:pr-[17rem] sm:pr-[30rem]">
                A, 616, Titanium Business Park, Nr. Makarba Railway Crossing,
                B/H. Divya Bhaskar Press, Ahmedabad - 380007
              </p>
            </div>

            <div className="sm:flex mb-10 sm:mb-5">
              <div className="py-8">
                <h4 className="sm:text-base blue__font">PHONE</h4>
                <p className="text-nowrap my-3">+91 7359910659</p>
              </div>
              <div className="sm:px-7 sm:py-8">
                <h4 className="sm:text-base blue__font">EMAIL</h4>
                <p className="my-3">waytoreacttechnologies@gmail.com</p>
              </div>
            </div>
            <span className="inline-flex mb-8">
              <a className="text-gray-500">
                <img src={linkedIn} alt="" />
              </a>
              <a className="ml-4 text-gray-500">
                <img src={fb} alt="" />
              </a>
              <a className="ml-4 text-gray-500">
                <img src={twitter} alt="" />
              </a>
            </span>
          </div>
          {/* <div className="w-full sm:w-1/2 flex justify-start md:justify-start lg:justify-end sm:justify-end px-10 sm:pl-44 md:pl-0 md:ml-20 my-10 lg:mt-20 xl:mt-44 md:mt-16">
              <span className="inline-flex sm:flex-row flex-col justify-start ">
                <a className="text-gray-500 uppercase" href="/projects_list">
                  portfolio
                </a>
                <a className="sm:ml-10 my-4 sm:my-0 text-gray-500">OUR TEAM</a>
                <a className="sm:ml-10 text-gray-500">CONTACT US</a>
              </span>
            </div> */}
        </div>

        <div className="text-center my-3">
          <p>All Rights Reserved © 2024 WAYTOREACT TECHNOLOGIES</p>
        </div>
      </section>
    </>
  );
};

export default Footer;
