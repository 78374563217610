import React from "react";
import bid_food from "../../assets/logos/bid_food_logo.png";
import LearnVern from "../../assets/logos/learn_vearn_logo.png";
import calary from "../../assets/logos/claris-logo.png";
import station from "../../assets/logos/station_logo.png";
import "./Project.css";
// import Icons from "../particle/Icons";

const Project = () => {
  return (
    <>
      <section className="body-font">
        {/* <Icons /> */}
        <div className="container px-5 md:py-10 lg:py-24 py-24 sm:py-24 mx-auto">
          {/* <div className="flex flex-col text-center w-full mb-20"> */}
          <div className="flex flex-col text text-center w-full mb-5 lg:mb-20">
            <h1
              className="text-4xl sm:text-7xl leading-normal md:text-6xl project_title font-semibold title-font mb-4 tracking-widest"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              OUR <span className="project"> PORTFOLIO</span>
            </h1>
          </div>

          <div className="flex flex-wrap -m-4">
            <div
              className="p-4 lg:w-1/2 md:w-1/2 w-full flex items-center justify-center"
              data-aos="fade-up-right"
              data-aos-duration="1000"
            >
              <div className="h-full flex flex-col items-center text-center sm:text-left">
                <a href="/projects1">
                  <img
                    alt="team"
                    className="flex-shrink-0 duration-300 hover:scale-110 rounded-lg w-44 h-26 object-cover object-center sm:mb-5 mb-4 shadow-none transition-all hover:shadow-lg hover:shadow-gray-400"
                    src={LearnVern}
                  />
                </a>
                <div className="flex-grow ">
                  <h2 className="title-font font-medium text-xl lg:text-lg">
                    LearnVern Online Courses
                  </h2>
                </div>
              </div>
            </div>
            <div
              className="p-10 sm:p-4 md:w-1/2 lg:w-1/2 flex w-full items-center justify-center"
              data-aos="fade-up-left"
              data-aos-duration="1000"
            >
              <div className="h-full flex flex-col items-center text-center sm:text-left">
                <a href="/projects2">
                  <img
                    alt="team"
                    className="flex-shrink-0 duration-300 hover:scale-110 rounded-lg w-44 h-26 object-cover object-center sm:mb-5 mb-4 shadow-none transition-all hover:shadow-lg hover:shadow-gray-400"
                    src={bid_food}
                  />
                </a>
                <div className="flex-grow">
                  <h2 className="title-font font-medium text-xl lg:text-lg text-gray-900">
                    Bidfood Home UAE
                  </h2>
                </div>
              </div>
            </div>
            <div
              className="p-10 sm:p-4 md:w-1/2 lg:w-1/2 flex w-full items-center justify-center"
              data-aos="fade-up-right"
              data-aos-duration="1000"
            >
              <div className="h-full flex flex-col items-center text-center sm:text-left">
                <a href="/projects3">
                  <img
                    alt="team"
                    className="flex-shrink-0 duration-300 hover:scale-110 rounded-lg w-44 h-26 object-cover object-center sm:mb-5 mb-4 shadow-none transition-all hover:shadow-lg hover:shadow-gray-400"
                    src={calary}
                  />
                </a>
                {/* <div className="flex-grow sm:pl-8"> */}
                <div className="flex-grow">
                  <h2 className="title-font font-medium text-xl lg:text-lg text-gray-900">
                    Claris App
                  </h2>
                </div>
              </div>
            </div>
            <div
              className="p-10 sm:p-4 md:w-1/2 lg:w-1/2 flex w-full items-center justify-center"
              data-aos="fade-up-left"
              data-aos-duration="1000"
            >
              <div className="h-full flex flex-col items-center text-center sm:text-left">
                <a href="/projects4">
                  <img
                    alt="team"
                    className="flex-shrink-0 duration-300 hover:scale-110 rounded-lg w-44 h-26 object-cover object-center sm:mb-5 mb-4 shadow-none transition-all hover:shadow-lg hover:shadow-gray-400"
                    src={station}
                  />
                </a>
                <div className="flex-grow">
                  <h2 className="title-font font-medium text-xl lg:text-lg text-gray-900">
                    Station Driver - Chauffeurs
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div
          className="flex flex-col  w-full mb-10"
          data-aos="zoom-in-left"
          data-aos-duration="3000"
        >
          <div className="font-medium text-2xl md:text-3xl sm:text-4xl text-center align-middle">
            <span className="project">TOTAL</span> PROJECTS{" "}
            <span className="project text-5xl sm:text-7xl md:text-6xl">32</span>
          </div>
        </div>

        <div
          className="flex flex-col text-center w-full mb-28"
          data-aos="zoom-in-right"
          data-aos-duration="2000"
        >
          <div className="font-medium text-2xl md:text-3xl sm:text-4xl text-center ">
            <span className="project">SATISFIED</span> CUSTOMERS{" "}
            <span className="project text-5xl sm:text-7xl mt-4 md:text-6xl">
              27
            </span>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default Project;
