import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./Tech_Slider.css";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper/modules";

const Tech_Slider = ({ images }) => {
  console.log(images.length);

  let duplicatedImages;
  if (images.length > 2) {
    duplicatedImages = [...images, ...images];
  } else {
    duplicatedImages = [...images];
  }

  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        speed={1000}
        breakpoints={{
          360: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          // 640: {
          //   slidesPerView: 3,
          //   spaceBetween: 30,
          // },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
        {duplicatedImages.map((item, idx) => (
          <SwiperSlide key={`${item}-${idx}`} className="mb-1">
            <div className="w-20 h-16">
              <img
                alt="html"
                className="object-cover object-center rounded"
                src={item}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default Tech_Slider;
